import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {Visibility } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
//import usersData from './UsersData'

class Perfil extends Component {

  constructor(props) {
    super(props);
    this.state = {     
            password :'',
            email:'',
            phone:'',
            openSnackBar:false,
            showPassword : false,
            message:'',
            done: true,
            token: '',
            }
  };

  componentDidMount(){
    var user = localStorage.getItem('user');
     // si hay token, redirecciona
    if(!user)
      this.props.history.push('/');

    var token = localStorage.getItem('token');
    this.setState({token: token});

    var currentUser = JSON.parse(user);
    var id = currentUser.ID;
    this.apiFectchUser(id);
  }

  async apiFectchUser(id_user){

        axios.get('https://cuerpopleno.es/wp-json/app/v1/user/',
          {
              params: {
                 secure_key:'1ec16e4666b5b2a9e2705ba7c739cf47',
                 user_id:id_user
              }
          })
        .then(response => { 
          console.log(response.data);
          if(response.data.ID){
            this.setState({ email: response.data.email});
            this.setState({ phone: response.data.phone});

          }

        })
        .catch(error => {
            console.log(error.response);
        });
    
  };

  handleSubmitPassword = () => {

    const password = this.state.password;    
    var user = localStorage.getItem('user');
    var currentUser = JSON.parse(user);
    var id = currentUser.ID;
    this.setState({done: false}); 

    this.apiChangePassword(id, password);
  }; 
 

  async apiChangePassword(id_user, new_password){

        axios.post('https://cuerpopleno.es/wp-json/app/v1/user/password',
          {
              secure_key: "1ec16e4666b5b2a9e2705ba7c739cf47",
              user_id: id_user,
              passwd:new_password
          })
        .then(response => { 
          console.log(response.data);
          if(response.data.user_update){
            this.setState({done: true});
            this.setState({ message: 'Contraseña cambiada'});
            this.handleOpenSnackBar();
            this.userUpdate();  
            
          }

        })
        .catch(error => {
            console.log(error.response);
        });
    
  };

  handleSubmitUser = () => {

    this.setState({done: false});
    var user = localStorage.getItem('user');
    var currentUser = JSON.parse(user);
    var id = currentUser.ID;
 
    var email = this.state.email;
    var phone = this.state.phone;
    this.apiChangeUser(id, email, phone);
  }; 
  
  async apiChangeUser(id_user, email, phone){

        axios.post('https://cuerpopleno.es/wp-json/app/v1/user/update',
          {
              secure_key: "1ec16e4666b5b2a9e2705ba7c739cf47",
              user_id: id_user,
              email:email,
              phone:phone
          })
        .then(response => { 
          console.log(response.data);
          if(response.data.user_update){

            this.setState({ email: response.data.email});
            this.setState({ phone: response.data.phone});  
            this.setState({done: true});  
            this.setState({ message: 'Datos guardados'});
            this.handleOpenSnackBar();      
          }

        })
        .catch(error => {
            console.log(error.response);
        });
    
  };

  handlePasswordChange = (event) => {        
    this.setState({password: event.target.value});  

  };

  handleMovilChange = (event) => {        
    this.setState({phone: event.target.value});  

  };


  handleEmailChange = (event) => {        
    this.setState({email: event.target.value});  

  };

  handleOpenSnackBar () {
    this.setState({ openSnackBar: true});
  };

  handleCloseSnackBar = (event, reason) => {
    this.setState({ openSnackBar: false});
  };

  userUpdate = () => {

    const interval = setInterval(() => { 
      console.log('SetInterval');
            localStorage.removeItem("user");
            this.props.history.push('/');
    }, 3000);

  }

  render() {


    return (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12}>
              <div className="header-section header-section__funcional">PERFIL DE USUARIO</div>
              <p></p>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Card>
                <form id="user">
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-email">Email</InputLabel>
                      <FilledInput
                        id="filled-adornment-email"
                        type='text'
                        value={this.state.email}
                        onChange={this.handleEmailChange}

                      />
                    </FormControl>
                    <p> </p>
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-movil">Movil</InputLabel>
                      <FilledInput
                        id="filled-adornment-movil"
                        type='text'
                        value={this.state.phone}
                        onChange={this.handleMovilChange}

                      />

                    </FormControl>
                    <div>
                        <CircularProgress color="primary" 
                                className={this.state.done ? 'nodisplay' : ''} 
   
                      />
                      <span className="span-error"></span>
                          <Button id="btn-login" variant="contained" color="secondary" onClick={this.handleSubmitUser}>
                          Guardar
                        </Button>

                    </div>
                    <p> </p>
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-password">Nueva Contraseña</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type='text'
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"

                              edge="end"
                            >
                              <Visibility /> 
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div>
                    <CircularProgress color="primary" 
                                className={this.state.done ? 'nodisplay' : ''} 
   
                      />
                      <span className="span-error">{this.state.textLogin}</span>
                        <Button id="btn-login" variant="contained" color="secondary" onClick={this.handleSubmitPassword}>
                          Guardar
                        </Button>
                     
                    </div>
                </form>
              </Card>
            </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.openSnackBar}
            autoHideDuration={4000}
            onClose={this.handleCloseSnackBar}
            message={this.state.message}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
           />
          </Grid>

    )
  }
}

export default Perfil;