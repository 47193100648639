import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';


class PerfilSessions extends Component {

  constructor(props) {
    super(props);
    this.state = {     
            currentMonth: new Date().getMonth(),
            openSnackBar:false,
            sessions : [],
            message:'',
            done: true,
            }
  };

  componentDidMount(){
    var user = localStorage.getItem('user');
     // si hay token, redirecciona
    if(!user)
      this.props.history.push('/');

    this.apiFectchSessions();
  }

 

  async apiFectchSessions(month = '') {
    var user = localStorage.getItem('user');
    if(user){
        var currentUser = JSON.parse(user);
        var id = currentUser.ID;
        var select_date = new Date();
        if(month === ''){
          month = parseInt(this.state.currentMonth) + 1;
        }else {
          month = parseInt(month) + 1;
        }
    
        var date_from = month + '/' + select_date.getDate() + '/' + select_date.getFullYear();
        console.log(date_from);
        axios.get('https://cuerpopleno.es/wp-json/app/v1/user/sessions',
          {
              params: {
                 secure_key:'1ec16e4666b5b2a9e2705ba7c739cf47',
                 user_id:id,
                 from: date_from, 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ done: true});
          this.setState({ loading: false});
          this.setState({ sessions: response.data});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });
    }
  }


  render() {

  const handleChangeMonth = (event) => {
    this.setState({ done: false });
    this.setState({ currentMonth: event.target.value });
    this.apiFectchSessions(event.target.value);
  };

    const groups = this.state.sessions;
    return (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12}>
              <div className="header-section header-section__funcional">MIS SESIONES</div>
            </Grid>
            <Grid   container
                  direction="row"
                  justify="center"
                  alignItems="center">
              <Grid item xs={12}>
                <p>
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentMonth}
                  onChange={handleChangeMonth}
                >
                  <MenuItem value={0}>Enero</MenuItem>
                  <MenuItem value={1}>Febrero</MenuItem>
                  <MenuItem value={2}>Marzo</MenuItem>
                  <MenuItem value={3}>Abril</MenuItem>
                  <MenuItem value={4}>Mayo</MenuItem>
                  <MenuItem value={5}>Junio</MenuItem>
                  <MenuItem value={6}>Julio</MenuItem>
                  <MenuItem value={7}>Agosto</MenuItem>
                  <MenuItem value={8}>Septiembre</MenuItem>
                  <MenuItem value={9}>Octubre</MenuItem>
                  <MenuItem value={10}>Noviembre</MenuItem>
                  <MenuItem value={11}>Diciembre</MenuItem>
                </Select>
                </p>
              </Grid>
            </Grid>
        {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (

      <Grid item xs={12} sm={6}>
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
        >
  
          {groups.map((session, indx) => {
               
            return( 
                  <Grid item xs={6} sm={3} key={indx}>        
    <Card  variant="outlined" className={`type_${session.tipo}`}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {session.day}
        </Typography>
        <Typography variant="h5" component="h2">
          {session.hour}
        </Typography>
        <Typography  color="textSecondary">
          {session.label}
        </Typography>
        <Typography variant="h6" component="h2">
          {session.tipo}
        </Typography>
      </CardContent>
    </Card>
                  </Grid>
            );
          })}
    
        </Grid>
        </Grid>
        )}

          </Grid>

    )
  }
}

export default PerfilSessions;