import React, { Component } from 'react';
import { URL_API, API_KEY } from '../../constants';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import FolderIcon from '@material-ui/icons/Folder';
//import WorkIcon from '@material-ui/icons/Work';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import axios from 'axios';

class AdminList extends Component {

  constructor(props) {
    super();

    this.state = {
      user: [],
      alertBirthday:'',
    }
  }

  componentDidMount(){
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({user: user})
    if (user.level !== '10') 
      this.props.history.push('/');

    this.apiFetchBirthday();
  }

  async apiFetchBirthday() {
    console.log('apiFetchBirthday');
        axios.get( URL_API + '/user/alertbirthday/',
          {
              params: {
                 secure_key:API_KEY,
                 
              }
          })
        .then(response => { 
          console.log(response.data);
            
              this.setState({ alertBirthday: response.data.message});
            

        })
        .catch(error => {
            console.log(error.response);
        });
  }


  handleListUsers = () => {
        this.props.history.push('/dashboard/users');
  }

  handleListFuncional = () => {
        this.props.history.push('/dashboard/groups/funcional');
  }

 handleListPilates = () => {
        this.props.history.push('/dashboard/groups/pilates');
  }

  handleListYoga = () => {
        this.props.history.push('/dashboard/groups/yoga');
  }

  handleListReadaptacion = () => {
    this.props.history.push('/dashboard/groups/readaptacion');
}

  handleListBirthday = () => {
        this.props.history.push('/dashboard/birthday');
  } 

  handleListInformes = () => {
        this.props.history.push('/dashboard/informes');
  } 

  handleListContabilidad = () => {
        this.props.history.push('/dashboard/contabilidad');
  } 

  handleListCalendarios = () => {
        this.props.history.push('/dashboard/calendarios');
  } 

  handleListSearch = (e) => {
    let query = document.getElementById("query").value;
    if(query.length > 0){


      query = query.replace(' ', '-');
      
      this.props.history.push('/dashboard/search/' + query);
      
    }else{
      this.props.history.push('/dashboard');
    }    
    
  }   



  render() {
    var user = this.state.user;
    if (user.ID == 1 || user.ID == 26 || user.ID == 53){
    return (
        <Grid id="dashboard" container justify="center">
          <Grid item xs={12} sm={12} md={6}>
            <Paper component="form" className="topadminlist" onSubmit={this.handleListSearch}>
               
                  <InputBase
                    id="query"
                    className="search"
                    placeholder="buscar..."
                    inputProps={{ 'aria-label': 'buscar...' }}
                  />
                  <IconButton type="button" className="btnsearch" aria-label="search" onClick={this.handleListSearch}>
                    <SearchIcon />
                  </IconButton>
               
            </Paper>        
            <List >
              <ListItem key={1} role={undefined} dense button onClick={this.handleListUsers}>
                  <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={1} primary={ 'Matrículas' } />
              </ListItem>

              <ListItem key={2} role={undefined} dense button  onClick={this.handleListFuncional}>
                  <ListItemAvatar>
                  <Avatar>
                    F
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={2} primary={ 'Grupos Funcional' }  />
              </ListItem>

              <ListItem key={3} role={undefined} dense button  onClick={this.handleListPilates}>
                  <ListItemAvatar>
                  <Avatar>
                    P
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={3} primary={ 'Grupos Pilates' } />
              </ListItem>

              <ListItem key={4} role={undefined} dense button  onClick={this.handleListYoga}>
                  <ListItemAvatar>
                  <Avatar>
                    Y
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={4} primary={ 'Grupos Yoga' } />
              </ListItem>

              <ListItem key={5} role={undefined} dense button  onClick={this.handleListReadaptacion}>
                  <ListItemAvatar>
                  <Avatar>
                    R
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={5} primary={ 'Readaptación' } />
              </ListItem>
      
              <ListItem key={6} role={undefined} dense button onClick={this.handleListInformes}>
                  <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={6} primary={ 'Informes' } />
              </ListItem>

              <ListItem key={7} role={undefined} dense button onClick={this.handleListContabilidad}>
                  <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={7} primary={ 'Contabilidad' } />
              </ListItem>
              <ListItem key={8} role={undefined} dense button onClick={this.handleListBirthday}>
                  <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={8} primary={ 'Cumpleaños' } />
              </ListItem>
              <ListItem key={9} role={undefined} dense button onClick={this.handleListCalendarios}>
                  <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={9} primary={ 'Calendario/Festivos' } />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
              <div class="todayList"><strong>Cumpleaños hoy</strong></div>
              <div dangerouslySetInnerHTML={{__html: this.state.alertBirthday}}></div>
          </Grid>

        </Grid>
    );
    }else{
    return (
        <Grid id="dashboard" container justify="center">
          <Grid item xs={12} sm={12} md={6}>
            <Paper component="form" className="topadminlist" onSubmit={this.handleListSearch}>
               
                  <InputBase
                    id="query"
                    className="search"
                    placeholder="buscar..."
                    inputProps={{ 'aria-label': 'buscar...' }}
                  />
                  <IconButton type="button" className="btnsearch" aria-label="search" onClick={this.handleListSearch}>
                    <SearchIcon />
                  </IconButton>
               
            </Paper>        
            <List >
              <ListItem key={1} role={undefined} dense button onClick={this.handleListUsers}>
                  <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={1} primary={ 'Matrículas' } />
              </ListItem>

              <ListItem key={2} role={undefined} dense button  onClick={this.handleListFuncional}>
                  <ListItemAvatar>
                  <Avatar>
                    F
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={2} primary={ 'Grupos Funcional' }  />
              </ListItem>

              <ListItem key={3} role={undefined} dense button  onClick={this.handleListPilates}>
                  <ListItemAvatar>
                  <Avatar>
                    P
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={3} primary={ 'Grupos Pilates' } />
              </ListItem>

              <ListItem key={4} role={undefined} dense button  onClick={this.handleListYoga}>
                  <ListItemAvatar>
                  <Avatar>
                    Y
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={4} primary={ 'Grupos Yoga' } />
              </ListItem>

              <ListItem key={5} role={undefined} dense button  onClick={this.handleListReadaptacion}>
                  <ListItemAvatar>
                  <Avatar>
                    R
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={5} primary={ 'Readaptación' } />
              </ListItem>
     
              <ListItem key={6} role={undefined} dense button onClick={this.handleListBirthday}>
                  <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={6} primary={ 'Cumpleaños' } />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
              <div class="todayList"><strong>Cumpleaños hoy</strong></div>
              <div dangerouslySetInnerHTML={{__html: this.state.alertBirthday}}></div>
          </Grid>
        </Grid>
    );    
    }
  }
}


AdminList.propTypes = {

}

export default AdminList;  