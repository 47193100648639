import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {AccountCircle, Visibility, VisibilityOff } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import logohome from '../../assets/img/logo_cuerpopleno-login.png';
import Typography from '@material-ui/core/Typography';



import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMessage:false,
      openMessageReacondicionar:false,
      openMessageNutricion:false,
      notification:[],
      userLogin:false,
      user_name:'',
      password :'',
      showPassword : false,
      textLogin:'',
    }
  };

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');
     //const tokenParse = JSON.parse(token);
    console.log('objetoObtenido: ', JSON.parse(user));
     // si hay token, redirecciona

    if(user){
      this.setState({ userLogin: user});
    }


    this.apiFetchMessage();

  }

  async apiFetchMessage(date = '') {
    var user = localStorage.getItem('user');
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;


        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        axios.get('https://cuerpopleno.es/wp-json/app/v1/user/messages/',
          {
              params: {
                 secure_key:'1ec16e4666b5b2a9e2705ba7c739cf47',
                 user_id:id,
              }
          })
        .then(response => { 
          console.log(response.data);
            if(response.data.message){
              this.setState({ notification: response.data});
              this.setState({ openMessage: true});
            }

        })
        .catch(error => {
            console.log(error.response);
        });
    }
  }

  //Soltamos Plaza
  async apiDeleteMessage(message) {
    var user = localStorage.getItem('user');
    
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;
        
        axios.post('https://cuerpopleno.es/wp-json/app/v1/user/messages/', {
          secure_key: "1ec16e4666b5b2a9e2705ba7c739cf47",
          user_id: id,
          message_id:message
        })
        .then(response => { 

        })
        .catch(error => {
            console.log(error.response);

        });
    }
  }

  handleListPilates = () => {
        this.props.history.push('/home/sessions/pilates');
    }

  handleListFuncional = () => {
        this.props.history.push('/home/sessions/funcional');
    }

  handleListYoga = () => {
        this.props.history.push('/home/sessions/yoga');
    }

  handleListReadaptacion = () => {
      this.props.history.push('/home/sessions/readaptacion');
  }


  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleEmailChange = (event) => { 
         
    this.setState({user_name: event.target.value});  

  };

  handlePasswordChange = (event) => {        
    this.setState({password: event.target.value});  

  };

  handleSubmit = () => {

    const { user_name, password } = this.state;    

    this.login(user_name, password);
  };

  async login(username, password) {
      this.setState({ textLogin: ''});
      var token = localStorage.getItem('token');
      axios.post('https://cuerpopleno.es/wp-json/app/v1/user/login', {
        secure_key: "1ec16e4666b5b2a9e2705ba7c739cf47",
        user_name: username,
        passwd:password,
        token:token
      })
      .then(response => {
          console.log(response); 
        if(response.data.ID > 0){
          localStorage.setItem('user', JSON.stringify(response.data) );
          this.props.history.push('/home');
        }else{
          this.setState({ textLogin: 'usuario o contraseña incorrecto'});
        }
        
      })
      .catch(error => {
          console.log(error.response);
          this.props.history.push('/');
          this.setState({ textLogin: 'error'});
      });
  }

  render(){

  const handleClickNutricion = () => {
      this.setState({ openMessageNutricion: true});
   }
  const handleCloseNutricion = () => {
    this.setState({ openMessageNutricion: false});
  };

  const handleClickFisioterapia = () => {
     this.setState({ openMessageReacondicionar: true});
  };

  const handleCloseReacondicionar = () => {
    this.setState({ openMessageReacondicionar: false});
  };


  const handleClose = () => {
    this.setState({ openMessage: false});
  };

  const handleLeido = () => {
    this.apiDeleteMessage(this.state.notification.id_notification);
    this.setState({ openMessage: false});
  };
  console.log(this.state.userLogin);
  if(this.state.userLogin){

    return (
        <Grid container justify="center">
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-pilates" onClick={this.handleListPilates}>
              <img src="../../assets/img/menu-pilates-shadows.png"  className="responsive" alt=""  />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-funcional" onClick={this.handleListFuncional}>
              <img src="../../assets/img/menu-funcional-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-readapta" onClick={this.handleListReadaptacion}>
              <img src="../../assets/img/menu-readaptacion-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-yoga" onClick={this.handleListYoga}>
              <img src="../../assets/img/menu-yoga-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-yoga" onClick={handleClickFisioterapia}>
              <img src="../../assets/img/menu-osteopatia-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-yoga" onClick={handleClickNutricion}>
              <img src="../../assets/img/menu-nutricion-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Dialog
            open={this.state.openMessage}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
               <div dangerouslySetInnerHTML={{__html: this.state.notification.message}}></div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cerrar
              </Button>
              <Button onClick={handleLeido} color="primary">
                Leído
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.openMessageReacondicionar}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseReacondicionar}
            id="opyiondialog" fullScreen
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
      <Typography variant="h5" gutterBottom className="titleReadaptacion">
       ¿Quieres conocer nuestros servicios? (Reeducación Neuromuscular y Quiromasaje)
      </Typography>
      <Typography variant="body1" gutterBottom>
          El servicio de <span className="infoReadaptacion">Readaptación</span> de <span className="infoCuerpo">Cuerpo Pleno</span> te ayudará a recuperar un estado físico y funcional tras haber sufrido un proceso lesivo, evitando así que vuelva a remitirse ese mismo dolor.
      </Typography>
      <Typography variant="body1" gutterBottom>
La <span className="infoReadaptacion">Reeducación Neuromuscular</span> es un conjunto de técnicas de mejora de las funciones músculo-articulares y de recuperación de lesiones, basadas en las últimas investigaciones sobre el control motor. El objetivo de la RN es reprogramar las respuestas motrices erróneas y automatizarlas de forma correcta para optimizar la función neuromuscular y que el problema desaparezca de raiz.

      </Typography>
      <Typography variant="body1" gutterBottom>
 El <span className="infoReadaptacion">Quiromasaje</span> lo basamos en un masaje descontracturante y relajante para liberar tensiones provocadas por sobrecargas musculares y malas posturas; o un masaje deportivo para preparar el tejido para ciertos esfuerzos y situaciones biomecánicas.
      </Typography>
      <Typography variant="overline" display="block" gutterBottom>
        <strong>Consulta única 30€</strong>
      </Typography>
      <Typography variant="overline" display="block" gutterBottom>
       <strong>Bono de 4 sesiones 100€</strong>
      </Typography>
      <Typography variant="button" display="block" gutterBottom className="linkReadaptacion">
       <a href="https://api.whatsapp.com/send?phone=34652645658&text=Hola, deseo solicitar cita en READAPTACIÓN. ¡Gracias!" target="_blank">¿Solicitar una cita?</a>
      </Typography>
                  
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseReacondicionar} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.openMessageNutricion}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseNutricion}
            id="opyiondialog" fullScreen
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
      <Typography variant="h5" gutterBottom className="titleNutricion">
      El servicio de <span className="infoNutricion">Nutrición</span> de <Typography variant="h5" className="infoCuerpo"> Cuerpo Pleno </Typography> dispone de dos opciones:
      </Typography>
      <Typography variant="overline" display="block" gutterBottom>
        <strong>- Consulta 25€</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
Consulta única con una entrevista personal para conocerte, pesarte y medirte los porcentajes de grasa corporal, grasa visceral, masa muscular y  metabolismo basal. Y la elaboración de un plan alimenticio ajustado a tus necesidades.
      </Typography>

      <Typography variant="overline" display="block" gutterBottom>
        <strong>- Bono de 2 meses 90€</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
Un bono con 4 citas con seguimiento personal cada 15 días. Sin duda la mejor opción para llevar un control exhaustivo con la nutricionista, mantener la motivación e ir ajustando tu dieta para lograr tus objetivos.
      </Typography>

      <Typography variant="button" display="block" gutterBottom className="linkNutricion">
       <a href="https://api.whatsapp.com/send?phone=34652645658&text=Hola, deseo solicitar cita en NUTRICIÓN. ¡Gracias!" target="_blank">¿Solicitar una cita?</a>
      </Typography>
                  
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNutricion} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid> 
    ); //return
  }else{
      return (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={4}>
              <Card>
              
                <form id="Login">
                    <img src={logohome} alt="cuerpo pleno" />
                    <Typography variant="overline" display="block" gutterBottom>
                      LOGIN
                    </Typography>
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-password">username</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        type='text'
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle"
                              edge="end"
                            >
                              <AccountCircle />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div></div>
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-password">fecha</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type='text'
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                             
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div>
                      <span className="span-error">{this.state.textLogin}</span>
                        <Button id="btn-login" variant="contained" color="secondary" onClick={this.handleSubmit}>
                          comenzar
                        </Button>
                     
                    </div>
                </form>
              </Card>
            </Grid>
          </Grid>
      );//return      
  }

  }; //render

}

Home.propTypes = {

}

export default withRouter(Home);