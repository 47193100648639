import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class Contabilidad extends Component {

  constructor() {
    super();
    this.state = {
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear(),
      currentType:0,
      useradmin:'',
      done: false,
      itemPilates: [],
      itemFuncional: [],
      itemYoga: [],
      itemMixto: [],
      itemVip: [],
      itemSo: [],
      itemSc: [],
      itemAtrasados:[],
      itemNutricion: [],
      itemBaile: [],
      itemReadapta: [],
      itemPersonal: [],
      itemMatricula: [],
      itemSumTransferecia:0,
      itemSumEfectivo:0,
      itemSumMes:0,
      userOption:'',
      openOption:'',
      sessionOption:'',
      usersMatricula:[],
      usersNutricion:[],
      usersReadaptacion:[],
      usersEpersonal:[],
      usersBaile:[]
    }
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

   this.setState({ useradmin: userjs.user_login}); 
   this.apiContabilidadUsers(this.state.currentMonth,this.state.currentYear,userjs.user_login);
   this.apiContabilidadUsersExtras(this.state.currentMonth,this.state.currentYear,userjs.user_login); 
  }

  async apiContabilidadUsers(month = '', year= '', useradmin='') {
         
        var cMonth ='';
        var cYear ='';
        if(month === ''){
          cMonth = this.state.currentMonth + 1;
        }else{
          cMonth = month + 1;
        }
        if(year === ''){
          cYear = this.state.currentYear;
        }else{
          cYear = year;
        }        
                

        axios.get( URL_API + '/admin/contabilidad',
          {
              params: {
                 secure_key:API_KEY,
                 month: cMonth,
                 year: cYear,
                 admin: useradmin 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ itemPilates: response.data.pilates});
          this.setState({ itemFuncional: response.data.funcional});
          this.setState({ itemYoga: response.data.yoga});
          this.setState({ itemMixto: response.data.mixto});
          this.setState({ itemVip: response.data.vip });
          this.setState({ itemSc: response.data.sc });
          this.setState({ itemSo: response.data.so });
          this.setState({ itemSumTransferecia: response.data.sum_transfer });
          this.setState({ itemSumEfectivo: response.data.sum_efectivo });
          this.setState({ itemSumMes: response.data.sum_mes });
          this.setState({ itemNutricion: response.data.nutricion});
          this.setState({ itemReadapta: response.data.readaptacion});
          this.setState({ itemPersonal: response.data.epersonal});
          this.setState({ itemMatricula: response.data.matriculas});
          this.setState({ itemBaile: response.data.baile});
          this.setState({ itemAtrasados: response.data.atrasados});
          this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
        });
        
  }

  async apiContabilidadUsersExtras(month = '', year= '', useradmin='') {
         
        var cMonth ='';
        var cYear ='';
        if(month === ''){
          cMonth = this.state.currentMonth + 1;
        }else{
          cMonth = month + 1;
        }
        if(year === ''){
          cYear = this.state.currentYear;
        }else{
          cYear = year;
        }        
        console.log('/admin/contabilidadextras');
        console.log(cMonth)
        console.log(cYear)
        console.log(useradmin)
        axios.get( URL_API + '/admin/contabilidadextras',
          {
              params: {
                 secure_key:API_KEY,
                 month: cMonth,
                 year: cYear, 
                admin: useradmin 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ usersMatricula: response.data.usersmatricula});
          this.setState({ usersNutricion: response.data.usersnutricion});
          this.setState({ usersReadaptacion: response.data.usersreadaptacion});
          this.setState({ usersEpersonal: response.data.usersepersonal});
          //this.setState({ usersBaile: response.data.usersbaile});
        })
        .catch(error => {
            console.log(error.response);
        });
        
  }  

  render() {

const handleChangeMonth = (event) => {
  
  this.setState({ currentMonth: event.target.value });
  this.setState({ done: false});
  this.apiContabilidadUsers(event.target.value,this.state.currentYear,this.state.useradmin);
  this.apiContabilidadUsersExtras(event.target.value,this.state.currentYear,this.state.useradmin);
};

const handleChangeYear = (event) => {
  
  this.setState({ currentYear: event.target.value });
  this.setState({ done: false});
  this.apiContabilidadUsers(this.state.currentMonth,event.target.value,this.state.useradmin);
  this.apiContabilidadUsersExtras(this.state.currentMonth,event.target.value,this.state.useradmin);
};

const handleUserExtras = (event) => {
  this.apiContabilidadUsersExtras(this.state.currentMonth,this.state.currentYear,this.state.useradmin);
};
  return (

    <Grid id="adminformes" container justify="center">

              <Grid item xs={12}>
              <Paper component="form" className="topinformer">
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentMonth}
                  onChange={handleChangeMonth}
                >
                  <MenuItem value={0}>Enero</MenuItem>
                  <MenuItem value={1}>Febrero</MenuItem>
                  <MenuItem value={2}>Marzo</MenuItem>
                  <MenuItem value={3}>Abril</MenuItem>
                  <MenuItem value={4}>Mayo</MenuItem>
                  <MenuItem value={5}>Junio</MenuItem>
                  <MenuItem value={6}>Julio</MenuItem>
                  <MenuItem value={7}>Agosto</MenuItem>
                  <MenuItem value={8}>Septiembre</MenuItem>
                  <MenuItem value={9}>Octubre</MenuItem>
                  <MenuItem value={10}>Noviembre</MenuItem>
                  <MenuItem value={11}>Diciembre</MenuItem>
                </Select>
                <Divider className="divider" orientation="vertical" />
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentYear}
                  onChange={handleChangeYear}
                >
                  <MenuItem value={2020}>2020</MenuItem>
                  <MenuItem value={2021}>2021</MenuItem>
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2023}>2023</MenuItem>
                  <MenuItem value={2024}>2024</MenuItem>
                </Select>
                </Paper> 
              </Grid>            
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={6}>
          <div className="contabilidad">
    <TableContainer component={Paper}>
      <Table className="contabilidadTable" size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Grupos</TableCell>
            <TableCell align="right">Transfer</TableCell>
            <TableCell align="right">Efectivo</TableCell>
            <TableCell align="right">Total Grupo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>PILATES</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemPilates.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemPilates.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemPilates.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemPilates.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemPilates.total_mes}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2" gutterBottom>FUNCIONAL</Typography>
                <Avatar className="infavatar inftotal contab">{this.state.itemFuncional.usuarios}</Avatar>
                <Avatar className="infavatar inffaltas contab">{this.state.itemFuncional.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemFuncional.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemFuncional.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemFuncional.total_mes}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>YOGA</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemYoga.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemYoga.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemYoga.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemYoga.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemYoga.total_mes}</TableCell>
            </TableRow> 

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>MIXTO</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemMixto.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemMixto.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemMixto.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemMixto.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemMixto.total_mes}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>VIP</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemVip.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemVip.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemVip.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemVip.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemVip.total_mes}</TableCell>
            </TableRow>      	

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>S.4</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemSc.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemSc.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemSc.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemSc.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemSc.total_mes}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>S.8</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemSo.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemSo.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemSo.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemSo.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemSo.total_mes}</TableCell>
            </TableRow>  

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>BAILE</Typography>
               <Avatar className="infavatar inftotal contab">{this.state.itemBaile.usuarios}</Avatar>
               <Avatar className="infavatar inffaltas contab">{this.state.itemBaile.usuarios_falta}</Avatar> 
              </TableCell>
              <TableCell align="right">{this.state.itemBaile.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemBaile.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemBaile.total_mes}</TableCell>
            </TableRow>       
       
            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>SUMA</Typography>
              </TableCell>
              <TableCell align="right">{this.state.itemSumTransferecia}</TableCell>
              <TableCell align="right">{this.state.itemSumEfectivo}</TableCell>
              <TableCell align="right">{this.state.itemSumMes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom>ATRASADOS</Typography>
              </TableCell>
              <TableCell align="right">{this.state.itemAtrasados.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemAtrasados.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemAtrasados.total_mes}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
           </div>
          <div className="contabilidad">
      <Typography variant="h6" gutterBottom>
        Otros Servicios
      </Typography>
    <TableContainer component={Paper}>
      <Table className="contabilidadTable" size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Grupos</TableCell>
            <TableCell align="right">Transfer</TableCell>
            <TableCell align="right">Efectivo</TableCell>
            <TableCell align="right">Total Grupo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom onClick={handleUserExtras}>NUTRICIÓN</Typography>
              </TableCell>
              <TableCell align="right">{this.state.itemNutricion.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemNutricion.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemNutricion.total_mes}</TableCell>
            </TableRow>
                <TableRow>
                  <TableCell colSpan="4">
                    <List dense={true}>
                                {this.state.usersNutricion.map((user, indx) => {

                                  return( 
                                    <ListItem key={indx} dense className={`userpay_${user.pay} usermetodo_${user.metodo} userstate_${user.state}`}>

                                    <ListItemText  primary={`${user.firstname} ${user.lastname}`}

                                     />
                                     
                                      <ListItemSecondaryAction >
                                        <Avatar className="infavatar inftotal" id={`id-${user.user_id}`}>{user.pago}</Avatar>
                                      </ListItemSecondaryAction>                    
                                    </ListItem>

                                  );
                  
                                })}
                    </List>                 
                  </TableCell>
                </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2" gutterBottom onClick={handleUserExtras}>READAPTACIÓN</Typography>
              </TableCell>
              <TableCell align="right">{this.state.itemReadapta.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemReadapta.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemReadapta.total_mes}</TableCell>
            </TableRow>
                <TableRow>
                  <TableCell colSpan="4">
                    <List dense={true}>
                                {this.state.usersReadaptacion.map((user, indx) => {

                                  return( 
                                    <ListItem key={indx} dense className={`userpay_${user.pay} usermetodo_${user.metodo} userstate_${user.state}`}>

                                    <ListItemText  primary={`${user.firstname} ${user.lastname}`}

                                     />
                                     
                                      <ListItemSecondaryAction >
                                        <Avatar className="infavatar inftotal" id={`id-${user.user_id}`}>{user.pago}</Avatar>
                                      </ListItemSecondaryAction>                    
                                    </ListItem>

                                  );
                  
                                })}
                    </List>                 
                  </TableCell>
                </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom onClick={handleUserExtras}>E. PERSONAL</Typography>
              </TableCell>
              <TableCell align="right">{this.state.itemPersonal.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemPersonal.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemPersonal.total_mes}</TableCell>
            </TableRow> 
                <TableRow>
                  <TableCell colSpan="4">
                    <List dense={true}>
                                {this.state.usersEpersonal.map((user, indx) => {

                                  return( 
                                    <ListItem key={indx} dense className={`userpay_${user.pay} usermetodo_${user.metodo} userstate_${user.state}`}>

                                    <ListItemText  primary={`${user.firstname} ${user.lastname}`}

                                     />
                                     
                                      <ListItemSecondaryAction >
                                        <Avatar className="infavatar inftotal" id={`id-${user.user_id}`}>{user.pago}</Avatar>
                                      </ListItemSecondaryAction>                    
                                    </ListItem>

                                  );
                  
                                })}
                    </List>                 
                  </TableCell>
                </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
              <Typography variant="subtitle2" gutterBottom onClick={handleUserExtras}>MATRÍCULAS</Typography>
              </TableCell>
              <TableCell align="right">{this.state.itemMatricula.total_transfer}</TableCell>
              <TableCell align="right">{this.state.itemMatricula.total_efectivo}</TableCell>
              <TableCell align="right">{this.state.itemMatricula.total_mes}</TableCell>
            </TableRow>    
                <TableRow>
                  <TableCell colSpan="4">
                    <List dense={true}>
                                {this.state.usersMatricula.map((user, indx) => {

                                  return( 
                                    <ListItem key={indx} dense className={`userpay_${user.pay} usermetodo_${user.metodo} userstate_${user.state}`}>

                                    <ListItemText  primary={`${user.firstname} ${user.lastname}`}

                                     />
                                     
                                      <ListItemSecondaryAction >
                                        <Avatar className="infavatar inftotal" id={`id-${user.user_id}`}>{user.pago}</Avatar>
                                      </ListItemSecondaryAction>                    
                                    </ListItem>

                                  );
                  
                                })}
                    </List>                 
                  </TableCell>
                </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
           </div>
        </Grid>
    )}

     </Grid>

      );//return



  } //render

}

Contabilidad.propTypes = {

}

export default Contabilidad;  