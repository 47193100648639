import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import axios from 'axios';
import { URL_API, API_KEY } from '../constants';

class UserDialog extends Component {

  constructor(props) {
    super();

    this.state = {
      user: [],
      userGroups:[],
      userNotes:[],
      user_id:0,
      session_id:0,
      openOptionEdit:false,
      openOptionGroup:false,
      openOptionSessions:false,
      openOptionContable:false,
      openModal:false,
      openModalDelete:false,
      done: true,
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear(),
      currentMonthPago:new Date().getMonth(),
      currentYearPago:new Date().getFullYear(),
      MonthBaja:new Date().getMonth(),
      YearBaja:new Date().getFullYear(),
      currentMetodo:0,
      currentPagoResume:[],
      currentAtrasado:0,
      currentPago:0,
      currentLevel:0,
      currentQuincena:'Q0',
      userInfo:[],
      currentName:'',
      currentLastName:'',
      currentBirth:'',
      currentNicename:'',
      currentPassw:'',
      currentNote:'',
      currentEval:'',
      message:'',
      expanded:false,
      groupDayL:[],
      groupDayM:[],
      groupDayX:[],
      groupDayJ:[],
      groupDayV:[],
      groupAdd:0,
      groupDel:0,
      sessionDel:0,
      sessionAdd:0,
      sessionDay:0,
      sessionLabel:'',
      loading:false,
      openAddGroup:false,
      openDelGroup:false,
      openAddSession:false,
      openDelSession:false,
      calendarItems:[],
      sessionsItems:[],
      selected:{},
      messagePago:'',
      messageBaja:'',
      currentPagoGroup:'',
    }
    this.renderList = this.renderList.bind(this);

    this.handleClickAddSession = this.handleClickAddSession.bind(this);
  }

  componentDidMount() {
 console.log(this.props.currentmonth)
    if(this.props.currentmonth >= 0){
      this.setState({ currentMonth: this.props.currentmonth});
      this.setState({ currentMonthPago: this.props.currentmonth});

    }
    if(this.props.currentyear >= 0){
      this.setState({ currentYear: this.props.currentyear});
      this.setState({ currentYearPago: this.props.currentyear});
    }
    this.setState({ session_id: this.props.idSession});

    this.setState({ user_id: this.props.idUser});
    this.apiCustomerInfo(this.props.idUser, this.state.currentMonthPago, this.state.currentYearPago);
    if( this.props.idSession == '-10'){
      this.setState({ openOptionContable: true});
    }
    this.apiCustomerInfoNotes(this.props.idUser,this.props.idSession);
  }

   renderList(el){
    
      var day_label = el.type + ' ' + el.hour_group_format;
      var selection = this.state.selected[el.id_session] ? 'select' : 'unselect';
      var str_class = 'chip ' + el.type + '_' + selection;
      return <div id={`se_${el.id_session}`} 
                onClick={this.handleClickAddSession.bind(this,el.id_session)} 
                className={str_class}>
                <span>{day_label} </span>
                 <span class="total">{el.total} </span>
                 <span class="free">{el.libre}</span>
                <span class="wait">{el.espera}</span>

            </div>;
   }

  handleClickAddSession(el){
    var sessionadd = this.state.sessionAdd;
    var selected = this.state.selected;
    selected[sessionadd] = false;
    selected[el] = !selected[el];
    this.setState({ sessionAdd: el});
    this.setState({selected: selected});
      
    }


  async apiCustomerInfo(user = '', month = '', year = '') {
       
        var cMonth = month + 1;
        var cYear = year;
        axios.get( URL_API + '/admin/account/user',
          {
              params: {
                 secure_key:API_KEY,
                 user_id:user,
                 month:cMonth,
                 year:cYear,
              }
          })
        .then(response => { 

         console.log(response.data);
          if(response.data){
           this.setState({ user: response.data});
           this.setState({ userGroups: response.data.groups});
          
            //USUARIO
            this.setState({ currentName: response.data.firstname});
            this.setState({ currentLastName: response.data.lastname});
            this.setState({ currentBirth: response.data.birthday});
            this.setState({ currentNicename: response.data.userlogin});
            this.setState({ currentEval: response.data.evaluation});
            this.setState({ currentLevel: response.data.level});
            this.setState({ currentMetodo: response.data.metodo});
            this.setState({ currentPago: response.data.pago});
            this.setState({ currentPagoGroup: response.data.level});
            this.setState({ currentQuincena: response.data.quincena});
            this.setState({ currentPagoResume: response.data.payresume});

            this.setState({ message: response.data.message});

            this.setState({ done: false});
            
          }else{
            this.setState({ user: [{'firstname':'No encontrado', 'lastname':''}]});
          }
          this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });    

  }

  async apiCustomerInfoNotes(user, session) {
      
        axios.post( URL_API + '/admin/getusernotes/', {
          secure_key: API_KEY,
          user_id: user,
          session_id:session,
        })
        .then(response => { 
           console.log(response.data);
            this.setState({ userNotes: response.data.notas});
        })
        .catch(error => {
            console.log(error.response);
            
        });    

  }

  async apiCustomerGroups(month = 0, year = 0) {
    var select_month = '';
    var select_year = '';
      if(month !== 0){
        select_month = month + 1;
      }else{
        select_month = this.state.currentMonth + 1;
      }

      if(year !== 0){
        select_year = year;
      }else{
        select_year = this.state.currentYear;
      }
  		var user_id = this.state.user_id;
      
       console.log(month);
        axios.get( URL_API + '/admin/account/groups',
          {
              params: {
                 secure_key:API_KEY,
                 user:user_id,
                 month:select_month,
                 year:select_year,
              }
          })
        .then(response => { 
          console.log(response.data)
          if(response.data){
            this.setState({groupDayL:response.data.lunes});
            this.setState({groupDayM:response.data.martes});
            this.setState({groupDayX:response.data.miercoles});
            this.setState({groupDayJ:response.data.jueves});
            this.setState({groupDayV:response.data.viernes});
            this.setState({ loading: false});
          }else{
            
          }
          
        })
        .catch(error => {
            console.log(error.response);
            
        });    

  }

  //Actualizar Usuario
  async apiUpdateUser() {
        
        let user_id = this.state.user_id;
        let name = this.state.currentName;
        let lastname = this.state.currentLastName;
        let birth = this.state.currentBirth;
        let nicename = this.state.currentNicename;
        let passw = this.state.currentPassw;
        let month = this.state.currentMonth;
        let level = this.state.level;
        let quincena = this.state.quincena;
        let evaluacion = this.state.currentEval;

        axios.post( URL_API + '/admin/updateuser/', {
          secure_key: API_KEY,
          user: user_id,
          name: name,
          lastname: lastname,
          birth: birth,
          nicename: nicename,
          passw: passw,
          evaluacion: evaluacion,
        })
        .then(response => { 
         	console.log(response.data);
          this.setState({ message: response.data.message}); 
          this.setState({ expanded: true});  
         
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ message: error.response}); 
            this.setState({ expanded: true});
        });
    
  }

  //Crear nota dleusuario
  async apiSetUserNote(alert = 0) {
        
        let user_id = this.state.user_id;
        let session_id = this.state.session_id;
        let note = this.state.currentNote;
        axios.post( URL_API + '/admin/addusernote/', {
          secure_key: API_KEY,
          user: user_id,
          session: session_id,
          nota: note,
          alert: alert,
        })
        .then(response => { 
          console.log(response.data);
          this.setState({ expanded: true});  
          this.apiCustomerInfoNotes(response.data.user,response.data.session);
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ expanded: true});
        });
    
  }

  //Borrar nota dleusuario
  async apiDelNote(id_note = 0) {
        
        let user_id = this.state.user_id;
        let session_id = this.state.session_id;
        let note = this.state.currentNote;
        axios.post( URL_API + '/admin/delusernote/', {
          secure_key: API_KEY,
          user: user_id,
          session: session_id,
          id_note: id_note,
        })
        .then(response => { 
          console.log(response.data);
         
          this.apiCustomerInfoNotes(user_id);
        })
        .catch(error => {
            console.log(error.response);
           
        });
    
  }

  //Baja de Usuario
  async apiBajaUser() {
        
        let user_id = this.state.user_id;
        let month = this.state.MonthBaja + 1;
        let year = this.state.YearBaja;
        axios.post( URL_API + '/admin/bajauser/', {
          secure_key: API_KEY,
          user: user_id,
          month: month,
          year:year,
        })
        .then(response => { 
         	console.log(response.data);
           this.setState({ messageBaja: response.data.message});
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }

  //Baja de Usuario Definitiva
  async apiBajaUserDef() {
        
        let user_id = this.state.user_id;
        let month = this.state.MonthBaja + 1;
        let year = this.state.YearBaja;
        axios.post( URL_API + '/admin/bajauserdef/', {
          secure_key: API_KEY,
          user: user_id,
          month: month,
          year:year,
        })
        .then(response => { 
          console.log(response.data);
           this.setState({ messageBaja: response.data.message});
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }

  //Asignar grupo al usuario
  async apiAddUserGroup(desde = 'all', month = 0, year = 0) {
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }
       var select_year = '';
        if(year !== 0){
          select_year = year;
        }else{
          select_year = this.state.currentYear;
        }       
        let user_id = this.state.user_id;
        let group_id = this.state.groupAdd;
        axios.post( URL_API + '/admin/user/addgroup', {
          secure_key: API_KEY,
          user: user_id,
          group:group_id,
          month:select_month,
          year:select_year,
          desde:desde,
        })
        .then(response => { 
          console.log(response.data);
          this.apiCustomerGroups();
         
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }

  //Quitar al usuario del grupo
  async apiDelUserGroup(desde = 'all', month = 0, year = 0) {
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }  
       var select_year = '';
        if(year !== 0){
          select_year = year;
        }else{
          select_year = this.state.currentYear;
        }     
        let user_id = this.state.user_id;
        let group_id = this.state.groupDel;
        axios.post( URL_API + '/admin/user/delgroup', {
          secure_key: API_KEY,
          user: user_id,
          group:group_id,
          month:select_month,
          year:select_year,
          desde:desde,
        })
        .then(response => { 
          console.log(response.data);
          this.apiCustomerGroups();
         
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }

  //Actualizar quincena y tipo de usuario
  async apiUpdateUserGroup(month = 0, year = 0) {
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }   
       var select_year = '';
        if(year !== 0){
          select_year = year;
        }else{
          select_year = this.state.currentYear;
        }           
        let user_id = this.state.user_id;
        let level = this.state.currentLevel;
        let quincena = this.state.currentQuincena;

        axios.post( URL_API + '/admin/user/updategroup', {
          secure_key: API_KEY,
          user: user_id,
          month: select_month,
          year: select_year,
          quincena:quincena,
          level:level,
        })
        .then(response => { 
          console.log(response.data);
          this.setState({ loading: false});
           
         
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }

  //BUSCAMOS LA SESIONES DEL USUARIO POR MES (mostrar calendario)
  async apiFectchSessions(month = 0, year = 0) {
      var select_date = new Date();
       var select_month = '';
       var select_year = '';
        if(month != 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }  

        if(year != 0){
          select_year = year;
        }else{
          select_year = this.state.currentYear;
        }

        var user = localStorage.getItem('user');
        var currentUser = JSON.parse(user);
        var admin_id = currentUser.ID;
             
        let user_id = this.state.user_id;
        var date_from = select_month + '/' + select_date.getDate() + '/' + select_year;
        console.log(date_from);
        axios.get( URL_API + '/user/sessions',
          {
              params: {
                 secure_key:API_KEY,
                 user_id:user_id,
                 from: date_from, 
                 admin:admin_id
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ calendarItems: response.data});
          this.setState({ loading: false});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });
    
  }

  //BUSCAMOS LA SESIONES DEL DIA ELEGIDO
  async apiSessionsDay(day,month = 0, year= 0) {
      var select_date = new Date();
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }  
       var select_year = '';
        if(year !== 0){
          select_year = year;
        }else{
          select_year = this.state.currentYear;
        }      
        let user_id = this.state.user_id;
        let day_session = day;

        axios.get( URL_API + '/admin/sessionsday',
          {
              params: {
                 secure_key:API_KEY,
                 user:user_id,
                 month: select_month,
                 year: select_year,
                 day: day_session, 
              }
          })
        .then(response => { 
          console.log('SESISIONS DAY');
          console.log(response.data);
          
          this.setState({ sessionsItems: response.data.sessions});
          this.apiFectchSessions();

        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });
    
  }

  //Quitar al usuario de la session
  async apiDelUserSession(month = 0, year = 0) {
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }       
        let user_id = this.state.user_id;
        let session_id = this.state.sessionDel;
       

        axios.post( URL_API + '/admin/user/delsession', {
          secure_key: API_KEY,
          user: user_id,
          month: select_month,
          session:session_id,
        })
        .then(response => { 
          console.log(response.data);
          this.apiFectchSessions(); 
          
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }

  //Añadir al usuario a la session
  async apiAddUserSession(month = 0) {
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }       
        let user_id = this.state.user_id;
        let session_id = this.state.sessionAdd;
       

        axios.post( URL_API + '/admin/user/addsession', {
          secure_key: API_KEY,
          user: user_id,
          month: select_month,
          session:session_id,
        })
        .then(response => { 
          console.log(response.data);
          this.apiFectchSessions();
        })
        .catch(error => {
            console.log(error.response);

        });
  }

  //Buscar los datos del pago del mes
  async apiGetPay(month = 0, year = 0) {
       var select_month = month + 1;
       
       var select_year = year;
       
        let user_id = this.state.user_id;
       
        
        axios.get( URL_API + '/admin/user/getpay',
          {
              params: {
                 secure_key:API_KEY,
                 user:user_id,
                 month: select_month,
                 year: select_year,
              }
          })
        .then(response => { 
          console.log('PAGO DAY');
          console.log(response.data);
          
          this.setState({ currentMetodo: response.data.metodo});
          this.setState({ currentPago: response.data.pago});
          this.setState({ currentPagoGroup: response.data.paygroup});

        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });
  }

  //Realizar pao del usuario
  async apiUserPay(month = 0, year = 0) {
       var select_month = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonthPago + 1;
        }   
       var select_year = '';
        if(year !== 0){
          select_year =year;
        }else{
          select_year = this.state.currentYearPago;
        }     
        let user_id = this.state.user_id;
        let metodo = this.state.currentMetodo;
       	let pago = this.state.currentPago;
        let paygroup = this.state.currentPagoGroup;
        let atrasado = this.state.currentAtrasado;

		    var user = localStorage.getItem('user');
 		    var currentUser = JSON.parse(user);
        var nicename = currentUser.user_login;
        
        axios.post( URL_API + '/admin/user/pay', {
          secure_key: API_KEY,
          user: user_id,
          month: select_month,
          year: select_year,
          metodo:metodo,
          atrasado:atrasado,
          pago:pago,
          user_login:nicename,
          paygroup:paygroup
        })
        .then(response => { 
          console.log(response.data);
          this.setState({messagePago: response.data.message});
          if(this.state.session_id == '-10'){
             this.props.action();
             console.log('action');
          }
          this.setState({ openOptionContable: false});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({messagePago: 'Error en el cobro'});
        });
  }

  //Eliminiar usuario
  async apiUserDelete(month = 0, year = 0) {
     
        let user_id = this.state.user_id;
        

        var user = localStorage.getItem('user');
        var currentUser = JSON.parse(user);
        var nicename = currentUser.user_login;
        
        axios.post( URL_API + '/admin/user/delete', {
          secure_key: API_KEY,
          user: user_id,
          user_login:nicename
        })
        .then(response => { 
          console.log(response.data);
          this.setState({ message: response.data.message}); 
          this.setState({ expanded: true});  
        })
        .catch(error => {
            console.log(error.response);
            this.setState({messagePago: 'Error en el cobro'});
        });
  }

  //Eliminar pago del usuario
  async apiDelContable(idcontable) {
        var cMonth = this.state.currentMonthPago + 1;
        var cYear = this.state.currentYearPago;
        let user_id = this.state.user_id;

        axios.post( URL_API + '/admin/contable/delete', {
          secure_key: API_KEY,
          user: user_id,
          idcontable: idcontable,
          month: cMonth,
          year: cYear,
        })
        .then(response => { 
          console.log(response.data);
           this.setState({ currentPagoResume: response.data.payresume});
        })
        .catch(error => {
            console.log(error.response);
        });
  }

  render() {


  const handleOpenOptionEdit = (e) => {
    this.setState({ openOptionEdit: true});       
  };  

  const handleCloseOptionEdit = (e) => {
    
   this.setState({ openOptionEdit: false});
  }; 
 
  const handleOpenOptionGroup = (e) => {
    this.setState({ openOptionGroup: true});
    this.apiCustomerGroups();   
  };  

  const handleCloseOptionGroup = (e) => {
    
   this.setState({ openOptionGroup: false});
  }; 
 
  const handleOpenOptionSessions = (e) => {
    this.setState({ openOptionSessions: true});
    this.setState({ loading: true});
    this.apiFectchSessions();   
  }; 

  const handleCloseOptionSessions = (e) => {
    
   this.setState({ openOptionSessions: false});
  };

  const handleSaveGroups = (e) => {
    
    this.apiSetCustomerGroups();
  }; 

  const handleActiveUser = (event, reason) => {
      localStorage.setItem('user_edit', this.state.user_id );
      this.props.history.push('/home/');
  };

    const handleChangeQuincena = (e) => {
     
        this.setState({currentQuincena: e.target.value})
    }

    const handleChangeLevel = (e) => {
     
        this.setState({currentLevel: e.target.value})
    }

    const handleChangeMonth = (e) => {
        this.setState({ loading: true});
        this.setState({currentMonth: e.target.value})
        this.apiCustomerGroups(e.target.value, this.state.currentYear);
        this.apiFectchSessions(e.target.value, this.state.currentYear);
    }

    const handleChangeYear = (e) => {
        this.setState({ loading: true});
        this.setState({currentYear: e.target.value})
        this.apiCustomerGroups(this.state.currentMonth,e.target.value);
        this.apiFectchSessions(this.state.currentMonth,e.target.value);
    }

    const handleChangeName = (e) => {
      
        this.setState({currentName: e.target.value})
    }

    const handleChangeLastname = (e) => {
     
        this.setState({currentLastName: e.target.value})
    }

    const handleChangeBirth = (e) => {
      
        this.setState({currentBirth: e.target.value})
    }

    const handleChangePassw = (e) => {
     
        this.setState({currentPassw: e.target.value})
    }

    const handleChangeNicename = (e) => {
     
        this.setState({currentNicename: e.target.value})
    }

    const handleChangeNote = (e) => {
    
        this.setState({currentNote: e.target.value})
    }

    const handleChangeEval = (e) => {
    
        this.setState({currentEval: e.target.value})
    }

    const handleClickUpdate = (e) => {
      this.setState({ expanded: false});
      this.apiUpdateUser();
    }

    const handleChangeMonthBaja = (e) => { 
        this.setState({MonthBaja: e.target.value})
    }

    const handleChangeYearBaja = (e) => { 
        this.setState({YearBaja: e.target.value})
    }

    const handleClickBaja = (e) => {
      
      this.setState({ openModal: true});
    }

    const handleClickElimina = (e) => {
      
      this.setState({ openModalDelete: true});
    }

    const handleClickCloseAlert = (e) => {
      
      this.setState({ expanded: false});
    }

    const handleAddNote = (e) => {
      
      this.apiSetUserNote(0);
    }

    const handleAddAlert = (e) => {
      
      this.apiSetUserNote(1);
    }

    const handleCloseModal = (e) => {
      
      this.setState({ openModal: false});
    }

    const handleAcceptModal = (e) => {
      this.apiBajaUser();
    }

    const handleAcceptModalDef = (e) => {
      this.apiBajaUserDef();
    }

    const handleClickAddGroup = (e) =>{
       let group_data = e.target.parentNode.id.split("_");
      this.setState({ groupAdd: group_data[1]});
      this.setState({ openAddGroup: true});
    }

    const handleAcceptModalAddGroup = (e) => {
      this.apiAddUserGroup('all');
      this.setState({ openAddGroup: false});
      this.setState({ loading: true});
   }

    const handleAcceptModalAddGroupToday = (e) => {
      console.log('add group today');
      this.apiAddUserGroup('today');
      this.setState({ openAddGroup: false}); 
      this.setState({ loading: true});  
    }
 
    const handleCloseModalAddGroup = (e) => {
      this.setState({ openAddGroup: false});
    }

    const handleClickDelGroup = (e) => {
       let group_data = e.target.parentNode.id.split("_");
      this.setState({ groupDel: group_data[1]});
      this.setState({ openDelGroup: true});
    }

    const handleAcceptModalDelGroup = (e) => {      
      this.apiDelUserGroup('all');
      this.setState({ openDelGroup: false});
      this.setState({ loading: true});
      
   }

    const handleAcceptModalDelGroupToday = (e) => {
      this.apiDelUserGroup('today');
      this.setState({ openDelGroup: false}); 
      this.setState({ loading: true});
       
    }
 
    const handleCloseModalDelGroup = (e) => {
      this.setState({ openDelGroup: false});
    }

    const handleCloseOptionGroupSave= (e) => {
      this.apiUpdateUserGroup();
      this.setState({ openOptionGroup: false});
      this.apiCustomerInfo(this.state.user_id);
    }

    const handleClickDay = (e) => {
      let session_day = e.target.id.split("_");
      this.setState({ sessionDay: session_day[1]});
      this.setState({ sessionLabel: session_day[2]});
      this.apiSessionsDay(session_day[1]);
      this.setState({ openAddSession: true});
    }

    const handleClickDelModalSession = (e) => {
      let session_data = e.target.parentNode.id.split("_");
     
      this.setState({ sessionDel: session_data[1]});
      this.setState({ openDelSession: true});
    }

    const handleAcceptModalDelSession = (e) => {
      this.apiDelUserSession();
      this.setState({ openDelSession: false});
      this.setState({ loading: true});
   }

    const handleCloseModalDelSession = (e) => {   
      this.setState({ openDelSession: false}); 
   }

    const handleAcceptModalDelete = (e) => {
      this.apiUserDelete();
      this.setState({ openModalDelete: false});
      this.setState({ loading: true});
   }

    const handleCloseModalDelete = (e) => {   
      this.setState({ openModalDelete: false}); 
   }

    const handleClickAddModalSession = (e) => {
      let session_data = e.target.parentNode.id.split("_");
      console.log('Añadimos usuario a sesion');
      this.setState({ sessionAdd: session_data[1]});
      this.setState({ openAddSession: true});
    }

    const handleAcceptModalAddSession = (e) => {
      this.apiAddUserSession();
      this.setState({ openAddSession: false});
      this.setState({ loading: true});
   }

    const handleCloseModalAddSession = (e) => {   
      this.setState({ openAddSession: false}); 
   }

   const handleOpenOptionContable = (e) => {
   		console.log('A cobrar');
   		this.setState({ openOptionContable: true});
   }

   const handleCloseModalContable = (e) => {
   		console.log('cancela');
   		this.setState({ openOptionContable: false});
   }

   const handleAcceptModalContable = (e) => {
   		console.log('acepta cobro');
   		
   		this.apiUserPay();
   }

    const handleChangeMonthPago = (e) => { 
        this.setState({currentMonthPago: e.target.value})
        this.setState({currentPagoResume: []});
                this.apiCustomerInfo(this.state.user_id, e.target.value, this.state.currentYearPago );
        this.apiGetPay(e.target.value, this.state.currentYearPago);
    }

    const handleChangeYearPago = (e) => { 
        this.setState({currentYearPago: e.target.value})
        this.setState({currentPagoResume: []});
                this.apiCustomerInfo(this.state.user_id, this.state.currentMonthPago, e.target.value );
        this.apiGetPay(this.state.currentMonthPago, e.target.value);
    }

    const handleChangeAtrasado = (e) => { 
        this.setState({currentAtrasado: e.target.value})
    }

    const handleChangeMetodo = (e) => { 
        this.setState({currentMetodo: e.target.value})
    }

    const handleChangePago = (e) => { 
        this.setState({currentPago: e.target.value})
    }

    const handleChangePagoGroup = (e) => { 
        this.setState({currentPagoGroup: e.target.value})
    }

    const handleClickDelNote = (e) => { 
        this.apiDelNote(e.target.id);
    }

    const handleClickContable = (e) => { 

        if(e.target.parentElement.id.length === 0){

        }else{
          console.log(e.target.parentElement.id);
          this.apiDelContable(e.target.parentElement.id);
        }
        
    }

  const user = this.state.user;
               
  var groups = this.state.userGroups;
  var str_groups = groups.map(function(group) {
    var array_day =['D','L','M','X','J','V','S'];
    var day_label = array_day[group.day_group] + ' ' + group.hour_group;
      return (<Chip size="small" className={`grouptype_${group.type}`} label={day_label} /> );
     });

  var list_notes = this.state.userNotes.map(function(item) {
      var userAlert='userAlert_0';
      if(item.alert == 1){
        userAlert = 'userAlert_1';
      }
      return (<div className="listNotes_item">
                          <Typography variant="caption" display="block">
                            <strong className={userAlert} onClick={handleClickDelNote} id={item.id_note}>{item.registro}</strong> {item.nota}
                          </Typography>
                      </div>);
      });

  var gr_lunes = this.state.groupDayL;
  var html_groups_lunes = gr_lunes.map(function(group) {
      var day_label = group.type + ' ' + group.hour_group_format;
      var selection = group.id_user > 0 ? 'select' : 'unselect';
      var str_class = 'chip ' + group.type + '_' + selection;
      var libres = group.plazas - group.total;
      if(libres <= 0){
        var class_libres = 'stRed';
      }else{
        var class_libres = 'stGreen';
      }
      libres =  Math.abs(libres);
      return ( <div id={`id_${group.id_group}`} 
                onClick={group.id_user > 0 ? handleClickDelGroup : handleClickAddGroup} 
                className={str_class}><span><strong className={class_libres}>{libres}</strong>{day_label}</span></div>);
     });

  var gr_martes = this.state.groupDayM;
  var html_groups_martes = gr_martes.map(function(group) {
      var day_label = group.type + ' ' + group.hour_group_format;
      var selection = group.id_user > 0 ? 'select' : 'unselect';
      var str_class = 'chip ' + group.type + '_' + selection;
      var libres = group.plazas - group.total;
      if(libres <= 0){
        var class_libres = 'stRed';
      }else{
        var class_libres = 'stGreen';
      }
      libres =  Math.abs(libres);
      return ( <div id={`id_${group.id_group}`} 
                onClick={group.id_user > 0 ? handleClickDelGroup : handleClickAddGroup} 
                className={str_class}><span><strong className={class_libres}>{libres}</strong>{day_label}</span></div>);
     });

  var gr_miercoles = this.state.groupDayX;
  var html_groups_miercoles = gr_miercoles.map(function(group) {
      var day_label = group.type + ' ' + group.hour_group_format;
      var selection = group.id_user > 0 ? 'select' : 'unselect';
      var str_class = 'chip ' + group.type + '_' + selection;
      var libres = group.plazas - group.total;
      if(libres <= 0){
        var class_libres = 'stRed';
      }else{
        var class_libres = 'stGreen';
      }
      libres =  Math.abs(libres);
      return ( <div id={`id_${group.id_group}`} 
                onClick={group.id_user > 0 ? handleClickDelGroup : handleClickAddGroup} 
                className={str_class}><span><strong className={class_libres}>{libres}</strong>{day_label}</span></div>);
     });

  var gr_jueves = this.state.groupDayJ;
  var html_groups_jueves = gr_jueves.map(function(group) {
      var day_label = group.type + ' ' + group.hour_group_format;
      var selection = group.id_user > 0 ? 'select' : 'unselect';
      var str_class = 'chip ' + group.type + '_' + selection;
      var libres = group.plazas - group.total;
      if(libres <= 0){
        var class_libres = 'stRed';
      }else{
        var class_libres = 'stGreen';
      }
      libres =  Math.abs(libres);
      return ( <div id={`id_${group.id_group}`} 
                onClick={group.id_user > 0 ? handleClickDelGroup : handleClickAddGroup} 
                className={str_class}><span><strong className={class_libres}>{libres}</strong>{day_label}</span></div>);
     });

  var gr_viernes = this.state.groupDayV;
  var html_groups_viernes = gr_viernes.map(function(group) {
      var day_label = group.type + ' ' + group.hour_group_format;
      var selection = group.id_user > 0 ? 'select' : 'unselect';
      var str_class = 'chip ' + group.type + '_' + selection;
      var libres = group.plazas - group.total;
      if(libres <= 0){
        var class_libres = 'stRed';
      }else{
        var class_libres = 'stGreen';
      }
      libres =  Math.abs(libres);
      return ( <div id={`id_${group.id_group}`} 
                onClick={group.id_user > 0 ? handleClickDelGroup : handleClickAddGroup} 
                className={str_class}><span><strong className={class_libres}>{libres}</strong>{day_label}</span></div>);
     });

  var html_pagoresume = this.state.currentPagoResume.map(function(pago) {
   
      var metodo = pago.metodo == 0 ? 'Efectivo' : 'Transferencia'
      return (<Chip size="small" label={`${pago.paygroup} ${metodo} ${pago.pago}`} id={pago.id_contable} onClick={handleClickContable} /> );
     });

  var html_listpay = ''; 

  var loading = '';
  if(this.state.loading === true){
    loading = <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>;
  }

  return (

    <Grid container justify="center" class="userDialog">
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
          <div>
            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"
                  >
              <Grid 
                    item   
                    className="topuser_groups"
                    direction="row"
                    justify="center"
                    alignItems="center">
                
                    <Typography variant="h5" >
                      {user.firstname}  {user.lastname}
                    </Typography>                  
                      <Typography variant="caption" display="block" >
                         {user.userlogin} 
                      </Typography>                     
              </Grid>
            </Grid>

            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"

                  >

                  <Grid item xs={2}>
                    <Avatar className="infavatar">{user.level} </Avatar>
                  </Grid>
                  <Grid item xs={2}>
                    <Avatar className="infavatar">{user.quincena}</Avatar>
                  </Grid>
                  <Grid item xs={2}>
                    <Avatar className="infavatar inftotal">{user.booking}</Avatar>
                  </Grid>
                  <Grid item xs={2}>
                      <Avatar className="infavatar infasiste">{user.asiste}</Avatar>
                  </Grid>
                  <Grid item xs={2}>
                      <Avatar className="infavatar inffaltas">{user.faltas}</Avatar>
                  </Grid> 
                  <Grid item xs={2}>
                      <Avatar className="infavatar inflibres">{user.free}</Avatar>
                  </Grid>
                              
            </Grid> 
            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"
               className="topuser"
                  >

                  <Grid item >
                   {str_groups}
                  </Grid>
                              
            </Grid>  
            <Grid   
              container 
              justify="center"
               className="topuser"
                  >
                  <Grid item xs={12}  className="topuser" justify="center">
                    <div className="bottomform">
                      <TextField
                        disabled
                        id="outlined-multiline-static"
                        label="Evaluación"
                        defaultValue={this.state.currentEval}
                        value={this.state.currentEval}
                        multiline
                        rows={4}
                        onChange={handleChangeEval}
                        variant="outlined"
                      />
                    </div>
                    <div className="listNotes">
                       {list_notes}
                    </div>
                      <TextField
                        id="outlined-multiline-static"
                        label="nota"
                        multiline
                        className="textNote"
                        rows={1}
                        onChange={handleChangeNote}
                        variant="outlined"
                      />
                      <Button variant="link" color="primary" onClick={handleAddAlert}>Añadir Alerta</Button> | <Button variant="link" color="primary" onClick={handleAddNote}>Añadir nota</Button>               
                  </Grid>
                  <Grid item xs={12}  className="optionuser" justify="center">
                      <Button variant="contained" color="primary" onClick={handleOpenOptionEdit}>Editar Perfil</Button>               
                  </Grid>
                   <Grid item xs={12}  className="optionuser" justify="center">
                      <Button variant="contained" color="primary" onClick={handleOpenOptionGroup}>Cambiar Grupos</Button>               
                  </Grid> 
                   <Grid item xs={12}  className="optionuser" justify="center">
                      <Button variant="contained" color="primary" onClick={handleOpenOptionSessions}>Cambiar Clases</Button>               
                  </Grid>
                   <Grid item xs={12}  className="optionuser" justify="center">
                      <Button variant="contained" color="primary" onClick={handleActiveUser}>Clonar usuario</Button>               
                  </Grid> 
                   <Grid item xs={12} className="optionuser" justify="center">
                      <Button variant="contained" color="secondary" onClick={handleOpenOptionContable}>Cobrar</Button>               
                  </Grid>                                                           
            </Grid>
          
            </div>
        )}
      <Dialog id="opyiondialoggroup" fullScreen open={this.state.openOptionGroup} onClose={handleCloseOptionGroup} >
        <DialogContent>

            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"
                  >
              <Grid 
                    item
                    className="topuser"   
                    direction="row"
                    justify="center"
                    alignItems="center">
                
                    <Typography variant="h5">
                      {user.firstname}  {user.lastname}
                    </Typography>                                      
              </Grid>

            </Grid>
            <Grid   
                      container
                  direction="row"
                  justify="center"
                  className="topuser"
              alignItems="center"
                  >
                  <Grid item xs={4}>
                      <Select
                        labelId="select-month"
                        id="month-select"
                        value={this.state.currentMonth}
                        onChange={handleChangeMonth}
                      >
                        <MenuItem value={0}>Enero</MenuItem>
                        <MenuItem value={1}>Febrero</MenuItem>
                        <MenuItem value={2}>Marzo</MenuItem>
                        <MenuItem value={3}>Abril</MenuItem>
                        <MenuItem value={4}>Mayo</MenuItem>
                        <MenuItem value={5}>Junio</MenuItem>
                        <MenuItem value={6}>Julio</MenuItem>
                        <MenuItem value={7}>Agosto</MenuItem>
                        <MenuItem value={8}>Septiembre</MenuItem>
                        <MenuItem value={9}>Octubre</MenuItem>
                        <MenuItem value={10}>Noviembre</MenuItem>
                        <MenuItem value={11}>Diciembre</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={3}>
                      <Select
                        labelId="select-year"
                        id="year-select"
                        value={this.state.currentYear}
                        onChange={handleChangeYear}
                      >
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                      </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                   
                      value={this.state.currentLevel}
                      onChange={handleChangeLevel}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      
                      <MenuItem value={'M'}>Mix</MenuItem>
                      <MenuItem value={'P'}>Pil</MenuItem>
                      <MenuItem value={'F'}>Fun</MenuItem>
                      <MenuItem value={'Y'}>Yoga</MenuItem>
                      <MenuItem value={'E'}>E</MenuItem>
                      <MenuItem value={'V'}>Vip</MenuItem>
                      <MenuItem value={'S.4'}>S.4</MenuItem>
                      <MenuItem value={'S.8'}>S.8</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                   
                      value={this.state.currentQuincena}
                    onChange={handleChangeQuincena}
                    
                    >
                      <MenuItem value={0}>Desac</MenuItem>
                      <MenuItem value='Q0'>Q0</MenuItem>
                      <MenuItem value='Q1'>Q1</MenuItem>
                      <MenuItem value='Q2'>Q2</MenuItem>
                      <MenuItem value='S.4'>S.4</MenuItem>
                      <MenuItem value='S.8'>S.8</MenuItem>
                      
                    </Select>
                  </Grid>
            </Grid>
            <Grid   
                      container
                      className="topuser"
                  direction="row"
                  justify="center"
              alignItems="top"
                  >
              {loading}
              <Grid item xs={2}> 
                <div>L</div>     
                {html_groups_lunes}
                                
              </Grid>
              <Grid item xs={2}> 
                <div>M</div> 
                  {html_groups_martes}                   
              </Grid>
              <Grid item xs={2}> 
                <div>X</div>     
                {html_groups_miercoles}                
              </Grid>
              <Grid item xs={2}>  
                 <div>J</div>  
                 {html_groups_jueves}  
              </Grid>
              <Grid item xs={2}> 
                <div>V</div>     
                {html_groups_viernes}              
              </Grid>
            </Grid>           

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionGroup} >
            Cerrar
          </Button>
          <Button onClick={handleCloseOptionGroupSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog id="opyiondialoggroup" fullScreen open={this.state.openOptionSessions} onClose={handleCloseOptionSessions} >
        <DialogContent>

            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"
                  >
              <Grid 
                    item
                    className="topuser"   
                    direction="row"
                    justify="center"
                    alignItems="center">
                
                    <Typography variant="h5">
                      {user.firstname}  {user.lastname}
                    </Typography>                                      
              </Grid>

            </Grid>
            <Grid   
                      container
                  direction="row"
                  justify="center"
                  className="topuser"
              alignItems="center"
                  >
                  <Grid item xs={6}>
                      <Select
                        labelId="select-month"
                        id="month-select"
                        value={this.state.currentMonth}
                        onChange={handleChangeMonth}
                      >
                        <MenuItem value={0}>Enero</MenuItem>
                        <MenuItem value={1}>Febrero</MenuItem>
                        <MenuItem value={2}>Marzo</MenuItem>
                        <MenuItem value={3}>Abril</MenuItem>
                        <MenuItem value={4}>Mayo</MenuItem>
                        <MenuItem value={5}>Junio</MenuItem>
                        <MenuItem value={6}>Julio</MenuItem>
                        <MenuItem value={7}>Agosto</MenuItem>
                        <MenuItem value={8}>Septiembre</MenuItem>
                        <MenuItem value={9}>Octubre</MenuItem>
                        <MenuItem value={10}>Noviembre</MenuItem>
                        <MenuItem value={11}>Diciembre</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6}>
                      <Select
                        labelId="select-year"
                        id="year-select"
                        value={this.state.currentYear}
                        onChange={handleChangeYear}
                      >
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                      </Select>
                  </Grid>
            </Grid>
            <Grid   
                      container
                      className="topuser"
                  direction="row"
                  justify="center"
              alignItems="top"
                  >
              <Grid item 
                  direction="row"
                  className="carditem" xs={12} >
              {loading}
                {this.state.calendarItems.map((item, indx) => {
                  if(item.type) {  
                var hours_list = item.hour.map((hour,i) => {

                    return (<Chip size="small" id={`session_${hour.id_session}`} className={`grouptype_${hour.hour_type}`} label={hour.hour_label} onClick={handleClickDelModalSession} />);
                  
                });

                  return( 
                    <Card  variant="outlined" className={`group_card group_${item.assigned}`} key={indx}>
                      <CardContent>
                        <Typography variant="caption" display="block" align="center" id={`day_${item.label}_${item.day}`} onClick={handleClickDay}>
                          {item.day}
                        </Typography>
                        <Typography variant="caption" display="block" align="center" id={`label_${item.label}_${item.day}`} onClick={handleClickDay}>
                          {item.label}
                        </Typography>
                           {hours_list}
                      </CardContent>
                    </Card>
                  );
                  }
                })}
              </Grid>
            </Grid>           

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionSessions} >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog id="opyiondialogedit" fullScreen open={this.state.openOptionEdit} onClose={handleCloseOptionEdit} >
        <DialogContent>

            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"
                  >
              <Grid 
                    item   
                    className="topuser"
                    direction="row"
                    justify="center"
                    alignItems="center">

                  <form className="userEdit" noValidate autoComplete="off">
                    <div className="wrapform">
                     <TextField id="firstname" 
                          label="Nombre" 
                          type="text" 
                          variant="filled" 
                          onChange={handleChangeName}
                          value={this.state.currentName} />
                     <TextField id="lastname" label="Apellidos" type="text" variant="filled" onChange={handleChangeLastname} value={this.state.currentLastName} />
                     <TextField id="birthday" label="Nacimiento" type="text" variant="filled" onChange={handleChangeBirth} value={this.state.currentBirth} />
                    
                     <TextField 
                        id="nicename" 
                        label="nicename" 
                        type="text" 
                        variant="filled"
                        onChange={handleChangeNicename} 
                        value={this.state.currentNicename}
                    />
                    <TextField 
                        id="passw" 
                        label="Contraseña"
                        onChange={handleChangePassw}
                        value={this.state.currentPassw}
                        type="text" 
                        variant="filled" 
                    />
                    </div>
                    <div className="bottomform">
                      <TextField
                        id="outlined-multiline-static"
                        label="Evaluación"
                        defaultValue={this.state.currentEval}
                        value={this.state.currentEval}
                        multiline
                        rows={4}
                        onChange={handleChangeEval}
                        variant="outlined"
                      />
                    </div>
                    <div className="bottomform">
                      <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                          <div className="alertText">
                            {this.state.message}
                          </div>
                          <div className="alertButton">
                          <Button color="secondary" onClick={handleClickCloseAlert}>X</Button>
                          </div>
                      </Collapse>                   
                      <Button variant="contained" color="primary" onClick={handleClickUpdate}>
                        Actualizar
                      </Button>
                    </div>
                   <Grid item xs={12}  className="topuser" justify="center">
                      <Button variant="contained" color="secondary" onClick={handleClickBaja}>Dar de baja</Button>               
                  </Grid> 
                   <Grid item xs={12}  className="topuser" justify="center">
                      <Button variant="contained" color="black" onClick={handleClickElimina}>Borrar</Button>               
                  </Grid>
                    <div>
                        {html_listpay} 
                    </div>
                  </form>
              </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionEdit} >
            Cerrar
          </Button>

        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"¿Dar de baja al usuario?"}</DialogTitle>
        <DialogContent>        
          <DialogContentText>
          	Se va a dar de baja al usuario de todos los grupos y sesiones en las que está apuntado para el mes seleccionado.
          </DialogContentText>
            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center">
                  <Grid item xs={6}>
                      <Select
                        labelId="select-month"
                        id="month-select"
                        value={this.state.MonthBaja}
                        onChange={handleChangeMonthBaja}
                      >
                        <MenuItem value={0}>Enero</MenuItem>
                        <MenuItem value={1}>Febrero</MenuItem>
                        <MenuItem value={2}>Marzo</MenuItem>
                        <MenuItem value={3}>Abril</MenuItem>
                        <MenuItem value={4}>Mayo</MenuItem>
                        <MenuItem value={5}>Junio</MenuItem>
                        <MenuItem value={6}>Julio</MenuItem>
                        <MenuItem value={7}>Agosto</MenuItem>
                        <MenuItem value={8}>Septiembre</MenuItem>
                        <MenuItem value={9}>Octubre</MenuItem>
                        <MenuItem value={10}>Noviembre</MenuItem>
                        <MenuItem value={11}>Diciembre</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6}>
                      <Select
                        labelId="select-year"
                        id="year-select"
                        value={this.state.YearBaja}
                        onChange={handleChangeYearBaja}
                      >
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                      </Select>
                  </Grid>
                </Grid>  
        </DialogContent>
        <p className="span-error">{this.state.messageBaja}</p>
        <DialogActions>
          <Button onClick={handleAcceptModalDef} color="secondary">
            Baja Definitiva
          </Button>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
          <Button onClick={handleAcceptModal} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openAddGroup}
        onClose={handleCloseModalAddGroup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Asigar el usuario al grupo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres asignar todas las sesiones del grupo este mes o a partir de hoy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalAddGroup} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalAddGroup} color="primary">
            todas
          </Button>
          <Button onClick={handleAcceptModalAddGroupToday} color="primary" autoFocus>
            desde hoy
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openDelGroup}
        onClose={handleCloseModalDelGroup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Quitar al usuario del grupo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres quitar de todas las sesiones del grupo este mes o desde de hoy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalDelGroup} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalDelGroup} color="primary">
            todas
          </Button>
          <Button onClick={handleAcceptModalDelGroupToday} color="primary" autoFocus>
            desde hoy
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openDelSession}
        onClose={handleCloseModalDelSession}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Quitar al usuario de la clase</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres quitar a este usuario de esta clase?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalDelSession} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalDelSession} color="primary">
            aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openModalDelete}
        onClose={handleCloseModalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Borrar al usuario de la app</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres quitar al usuario de las clases, los grupos y la app?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalDelete} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalDelete} color="primary">
            aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openAddSession}
        onClose={handleCloseModalAddSession}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <DialogContent>
          <Typography variant="h5" display="block">{`${this.state.sessionLabel} ${this.state.sessionDay}`}</Typography>
          {this.state.sessionsItems.map(this.renderList)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalAddSession} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalAddSession} color="primary">
            aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openOptionContable}
        onClose={handleCloseModalContable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <Grid   
                      container
                  direction="row"
                  justify="center"
              alignItems="center"
                  >
              <Grid 
                    item
                    className="topuser"   
                    direction="row"
                    justify="center"
                    alignItems="center">
                
                    <Typography variant="h5">
                      {user.firstname}  {user.lastname}
                    </Typography>                                      
              </Grid>

            </Grid>
            <Grid   
                      container
                  direction="row"
                  justify="center"
                  className="topuser"
              alignItems="center"
                  >
                  <Grid item xs={6} className="topuser">
                      <Select
                        labelId="select-month-pago"
                        id="month-pago-select"
                        value={this.state.currentMonthPago}
                        onChange={handleChangeMonthPago}
                      >
                        <MenuItem value={0}>Enero</MenuItem>
                        <MenuItem value={1}>Febrero</MenuItem>
                        <MenuItem value={2}>Marzo</MenuItem>
                        <MenuItem value={3}>Abril</MenuItem>
                        <MenuItem value={4}>Mayo</MenuItem>
                        <MenuItem value={5}>Junio</MenuItem>
                        <MenuItem value={6}>Julio</MenuItem>
                        <MenuItem value={7}>Agosto</MenuItem>
                        <MenuItem value={8}>Septiembre</MenuItem>
                        <MenuItem value={9}>Octubre</MenuItem>
                        <MenuItem value={10}>Noviembre</MenuItem>
                        <MenuItem value={11}>Diciembre</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6} className="topuser">
                      <Select
                        labelId="select-month-pago"
                        id="month-pago-select"
                        value={this.state.currentYearPago}
                        onChange={handleChangeYearPago}
                      >
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6} className="topuser">
                      <Select
                        labelId="select-metodo-group"
                        id="metodo-group-select"
                        value={this.state.currentPagoGroup}
                        onChange={handleChangePagoGroup}
                      >
                        <MenuItem value="P">Pilates</MenuItem>
                        <MenuItem value="F">Funcional</MenuItem>
                        <MenuItem value="Y">Yoga</MenuItem>
                        <MenuItem value="M">Mixto</MenuItem>
                        <MenuItem value="V">Vip</MenuItem>
                        <MenuItem value="S.4">S.4</MenuItem>
                        <MenuItem value="S.8">S.8</MenuItem>
                        <MenuItem value="MA">Matrícula</MenuItem>
                        <MenuItem value="NU">Nutrición</MenuItem>
                        <MenuItem value="RE">Readaptación</MenuItem>
                        <MenuItem value="EP">E. Personal</MenuItem>
                        <MenuItem value="BA">Baile</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6} className="topuser">
                      <Select
                        labelId="select-metodo"
                        id="metodo-select"
                        value={this.state.currentAtrasado}
                        onChange={handleChangeAtrasado}
                      >
                        <MenuItem value={0}>Normal</MenuItem>
                        <MenuItem value={1}>Atrasado</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6}>
                      <Select
                        labelId="select-metodo"
                        id="metodo-select"
                        value={this.state.currentMetodo}
                        onChange={handleChangeMetodo}
                      >
                        <MenuItem value={0}>Efectivo</MenuItem>
                        <MenuItem value={1}>Transferencia</MenuItem>
                        <MenuItem value={2}>No Asistió</MenuItem>
                      </Select>  
                  </Grid>
                  <Grid item xs={6}>
                     <TextField 
                        id="pago" 
                        label="pago" 
                        type="text" 
                        variant="filled"
                        value={this.state.currentPago}
                        onChange={handleChangePago}
                    />  
                  </Grid>
                  <Grid item xs={12} className="topuser">
                    {html_pagoresume}
                  </Grid>
                  <Grid item xs={12} className="topuser">
                      <Typography variant="caption" display="block" className="span-error">
                        {this.state.messagePago}
                      </Typography> 
                  </Grid>
            </Grid>        	
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalContable} color="primary">
            Cerrar
          </Button>
          <Button onClick={handleAcceptModalContable} color="primary" autoFocus>
            Cobrar
          </Button>
        </DialogActions>
      </Dialog>
     </Grid>

      ); //return



  } //render

}

UserDialog.propTypes = {

}

export default withRouter(UserDialog);  